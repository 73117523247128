/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/19
* @description
*   BindBankAccount.vue of WeTrade
*/

<template>
  <DialogTemplate>
    <template #title><t path="withdrawal_9">Add Bank account</t></template>
    <div class="flex-1">
      <p class="c-title f-md mt-32"><t path="withdrawal_10" :args="{name}">Payee's name: {{name}}</t></p>
      <div class="mt-8 mb-24">
        <div class="form-item block">
          <t path="withdrawal_11" custom #="{td}">
            <input class="input" type="text" v-model="account" :placeholder=" td || 'Bank Account Number'" />
          </t>
        </div>
        <div class="form-item block">
          <t path="withdrawal_12" custom #="{td}">
            <input class="input" type="text" v-model="ifsc" :placeholder=" td || 'IFSC Code'" />
          </t>
        </div>
      </div>
    </div>
    <template #confirm>
      <Button class="f-bold f-lg primary flex-1" @click="submit" :progress="progress">
        <t path="withdrawal_14" #="{td}" custom>{{ td || 'Submit' }}</t>
      </Button>
    </template>
  </DialogTemplate>
</template>

<script lang="ts">
import Button from '@/components/Button.vue'
import { showAlert } from '@/components/popup/popup'
import useRequest from '@/hooks/useRequest'
import { translate } from '@/i18n'
import { bindFA, FAAccountType } from '@/modules/out/out.api'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent, shallowRef } from 'vue'

const IFSCPattern = /^[A-Za-z0-9]{4}0[A-Za-z0-9]{6}$/

export default defineComponent({
  name: 'BindBankAccount',
  components: { Button, DialogTemplate },
  props: {
    name: String,
  },
  setup (props, ctx) {
    const [commit, progress] = useRequest(bindFA)
    const account = shallowRef('')
    const ifsc = shallowRef('')
    // const submit = bindBank(next)
    return {
      account,
      ifsc,
      submit () {
        if (IFSCPattern.test(ifsc.value)) {
          commit({
            type: FAAccountType.BANK_OF_IN,
            bankAccount: account.value,
            ifsc: ifsc.value,
          }).then(() => {
            ctx.emit('confirm')
            ctx.emit('close')
          })
        } else {
          showAlert(translate('withdrawal_72'))
        }
      },
      progress,
    }
  },
})
</script>
